<template>
  <div id="button-up">
    <v-fab class="button-up" color="white" v-scroll="onScroll" :active="fab" appear app size="40" @click="toTop" title="Ir al principio de la página"
      aria-label="Ir al principio de la página" icon="mdi-chevron-up">
    </v-fab>


  </div>
</template>
<script setup lang="ts">
import { useGoTo } from 'vuetify'
const goTo = useGoTo()
const fab = ref(false)
function onScroll(e) {
  if (typeof window === "undefined") return;
  const top = window.scrollY || 0;
  fab.value = top > 60;
}
function toTop() {
  goTo(0,{duration:500});
}
onScroll(null)
</script>

<style scoped>
.button-up{

}
</style>