<template>
   <CookieControl id="cookies" :locale="store.idioma" />
</template>
<script setup lang="ts">
import { useStore } from '../store/index'
const store = useStore();
const { gtag, initialize, enableAnalytics, disableAnalytics } = useGtag()
const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions,
} = useCookieControl()

// example: react to a cookie being accepted
watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (current?.includes('gtag')) {
      // cookie with id `google-analytics` got added
      if(store?.NOTARIA?.Personalizar?.GoogleAnalyticsID){
        console.log("initialice gtag")
        initialize(store.NOTARIA.Personalizar.GoogleAnalyticsID)
        
        gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted'
        })
        enableAnalytics(store.NOTARIA.Personalizar.GoogleAnalyticsID)
      }
    }else{
      disableAnalytics(store.NOTARIA.Personalizar.GoogleAnalyticsID)
    }
  },
  { deep: true },
)
</script>