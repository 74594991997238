<template>
  <footer :class="'footer-area footer-notin bg_color--' + 1" >
    <div class="footer-wrapper" v-if="NOTARIA && NOTARIA.Theme">
      <v-row align="end" class="row--0">
        <v-col md="6" sm="12" cols="12" v-if="NOTARIA.Theme.Footer.MostrarMapa">
          <div class="footer-left footer-left-notin">
            <div class="inner">
              <GoogleMap ref="mymap" api-key="AIzaSyCRbdQmLDJQz-JO8j3g_2J9yEOd27nJmvw"
                :center="{lat: NOTARIA.Contacto.Coordinates.lat, lng: NOTARIA.Contacto.Coordinates.lng }" :zoom="15"
                map-type-id="roadmap" class="map-responsive" :options="gmapOptions">
                <Marker :options="{position:{lat: NOTARIA.Contacto.Coordinates.lat, lng: NOTARIA.Contacto.Coordinates.lng },label:'N', title:NOTARIA.Nombre, clickable:true, draggable:false}"
                  @click="openWindow">
                  <InfoWindow @closeclick="window_open = false" :opened="window_open"
                    :options="{position:{lat: NOTARIA.Contacto.Coordinates.lat, lng: NOTARIA.Contacto.Coordinates.lng},pixelOffset: {
      width: 0,
      height: -35
    }
  }">
                    <b>{{ NOTARIA.Nombre }}</b><br><br>
                    {{ NOTARIA.Contacto.Direccion }}<br>
                    {{ NOTARIA.Contacto.CP }}<br>
                    {{ NOTARIA.Contacto.Municipio }}, {{ NOTARIA.Contacto.Provincia }}<br><br>

                    <a :href="NOTARIA.Contacto.EnlaceMaps" target="_blank">Ver en Mapa</a>
                  </InfoWindow>
                </Marker>
              </GoogleMap>
            </div>
          </div>
        </v-col>
        <!-- End .col -->
        <v-col :md="colmd" sm="12" cols="12">
          <div class="footer-right footer-right-notin">
            <v-row>
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12" class="mt_mobile--30">
                <div class="footer-widget horario-footer-notin">
                  <h3>
                    {{ NOTARIA.Theme.Footer.TituloHorario.Texto }}
                  </h3>
                  <p v-html="NOTARIA.Contacto.Horario.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>

                </div>
              </v-col>
              <!-- End Single Widget  -->
              <!-- Start Single Widget -->
              <v-col lg="6" sm="6" cols="12">
                <div class="footer-widget dcontacto-footer-notin">
                  <h3>{{ NOTARIA.Theme.Footer.TituloDContacto.Texto }}</h3>
                  <ul class="ft-link">
                    <li>
                      <a :href="'tel:' + NOTARIA.Contacto.Telefono"
                        :title="'Marcar teléfono de la notaria: ' + NOTARIA.Contacto.Telefono">
                        <v-icon class="mr-1" small :color="NOTARIA.Theme.Footer.ContactoCSS.Color">
                          mdi-phone
                        </v-icon>{{ NOTARIA.Contacto.Telefono }}
                      </a>
                    </li>
                    <li v-if="NOTARIA.Contacto.Movil">
                      <a :href="'tel:' + NOTARIA.Contacto.Movil"
                        :title="'Marcar móvil de la notaria: ' + NOTARIA.Contacto.Movil">
                        <v-icon class="mr-1" small :color="NOTARIA.Theme.Footer.ContactoCSS.Color">
                          mdi-phone
                        </v-icon>{{ NOTARIA.Contacto.Movil }}
                      </a>
                    </li>
                    <li>
                      <a :href="NOTARIA.Contacto.EnlaceMaps" title="Abrir dirección de la notaria en google maps">
                        <v-icon class="mr-1" small :color="NOTARIA.Theme.Footer.ContactoCSS.Color">
                          mdi-map-marker
                        </v-icon>{{ NOTARIA.Contacto.Direccion }}
                        {{ NOTARIA.Contacto.CP }},
                        {{ NOTARIA.Contacto.Municipio }}, {{ NOTARIA.Contacto.Provincia }}
                      </a>
                    </li>
                    <li v-if="NOTARIA.Contacto.Email">
                      <a :href="'mailto:' + NOTARIA.Contacto.Email"
                        :title="'Enviar correo electronico a la dirección de la Notaria: ' + NOTARIA.Contacto.Email">
                        <v-icon class="mr-1" small :color="NOTARIA.Theme.Footer.ContactoCSS.Color">
                          mdi-email
                        </v-icon>{{ NOTARIA.Contacto.Email }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="social-share-inner mb-6">
                  <ul class="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                    <li v-if="NOTARIA.Contacto.Facebook">
                      <a :href="NOTARIA.Contacto.Facebook" title="Ir a pagina de facebook">
                        <v-icon :color="NOTARIA.Theme.Footer.ContactoCSS.Color">mdi-facebook</v-icon>
                      </a>
                    </li>
                    <li v-if="NOTARIA.Contacto.LinkedIn">
                      <a :href="NOTARIA.Contacto.LinkedIn" title="Ir a pagina de linked in">
                        <v-icon :color="NOTARIA.Theme.Footer.ContactoCSS.Color">mdi-linkedin</v-icon>
                      </a>
                    </li>
                    <li v-if="NOTARIA.Contacto.Twitter">
                      <a :href="NOTARIA.Contacto.Twitter" title="Ir a pagina de twitter">
                        <v-icon :color="NOTARIA.Theme.Footer.ContactoCSS.Color">mdi-twitter</v-icon>
                      </a>
                    </li>
                    <li v-if="NOTARIA.Contacto.Instagram">
                      <a :href="NOTARIA.Contacto.Instagram" title="Ir a pagina de instagram">
                        <v-icon :color="NOTARIA.Theme.Footer.ContactoCSS.Color">mdi-instagram</v-icon>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-col>
              <!-- End Single Widget  -->
            </v-row>
            <v-row>
              <v-col>
                <div class="copyright-text-notin">
                  <p>
                    <a class="copyright-text-notin" href="https://notin.es">Copyright © {{ new Date().getFullYear() }}
                      UNIDATA</a> |
                    <a class="copyright-text-notin" href="https://controlpanel.notin.es/">{{ $t('panelControl') }}</a> |
                    <a class="copyright-text-notin" href="/avisolegal">{{ $t('avisoLegal') }}</a> |
                    <a class="copyright-text-notin" href="/politicacookies">{{ $t('politicaCookies') }}</a> |
                    <a class="copyright-text-notin" href="/protecciondatos">{{ $t('proteccionDatos') }}</a> |
                    <a class="copyright-text-notin" href="/accesibilidad">{{ $t('accesibilidad') }}</a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- End .col -->
      </v-row>
      <v-row v-if="NOTARIA.Theme.Footer.MostrarSubFooter">
        <v-col cols="12" class="pa-3 ">
          <FooterSubFooter />
        </v-col>
      </v-row>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map'
import { useStore } from '../../store/index.ts'
const store = useStore()
const {NOTARIA} = storeToRefs(store)

import { useUtils } from '#imports';
const {imagenIDtoSRC,$t}=useUtils()
//console.log(NOTARIA)
// const props = defineProps({
//   color: String
// })

const colmd = ref(6)
const window_open = ref(false)
const gmapOptions = ref({
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
  })
// const mapCenter = computed(()=>{
//    var r
//    debugger
//    if(NOTARIA && NOTARIA.Contacto && NOTARIA.Contacto.Coordinates){
//     r= {lat: NOTARIA.Contacto.Coordinates.lat, lng: NOTARIA.Contacto.Coordinates.lng }

//    }
//    console.log(r)
//    return r
// })
function created() {
  if (store.NOTARIA && store.NOTARIA.Theme && !store.NOTARIA.Theme.Footer.MostrarMapa) {
    colmd.value = 12
  }
}
function openWindow() {
  ////console.log(this);
  window_open.value = true
}
</script>
<style lang="scss">
.map-responsive {
  overflow: hidden;
  // padding-bottom: 56.25%;
  position: relative;
  height: 400px;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>