<template>
  <div id="HeaderNotinOne" v-if="NOTARIA && NOTARIA.Theme" v-scroll="onScroll">

    <!-- End sidebar mobile menu-->

    <v-app-bar :absolute="!NOTARIA.Theme.Menu.Fixed" scroll-behavior="elevate" 
      :class="'header-one-page-nav-notin default-header-notin menu-header-notin v-app-bar '+(NOTARIA.Theme.Menu.Transparent?'transparent':'')+scrolled" v-if="!drawer">


      <NuxtLink to="/" class="header-logo logo">
        <img :alt="'Logo ' + NOTARIA.Nombre" :src="imagenIDtoSRC(NOTARIA.Theme.Menu.Logo)" />
      </NuxtLink>
      <v-spacer></v-spacer>

      <nav class="hidden-sm-and-down">

        <v-btn class="theme--light"
          :title="$t(store.ModoAccesible ? 'DesactivarModoAccesibilidad' : 'ActivarModoAccesibilidad')" :ripple="false"
          text :aria-label="(store.ModoAccesible ? 'Desactivar' : 'Activar') + ' Modo Accesibilidad'"
          @click="store.toggleModoAccesible"
          v-if="NOTARIA.Personalizar.Accesibilidad"><v-icon>mdi-human</v-icon></v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/" v-if="NOTARIA.Theme.Menu.ShowInicio">{{
    getTitleItem('Inicio') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/notarios" v-if="NOTARIA.Theme.Menu.ShowNotarios">{{
    getTitleItem('Notario') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/equipo" v-if="NOTARIA.Theme.Menu.ShowEquipo">{{
    getTitleItem('Equipo') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/servicios" v-if="NOTARIA.Theme.Menu.ShowServicios">{{
    getTitleItem('Servicios') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/blog" v-if="NOTARIA.Theme.Menu.ShowBlog"> {{
    getTitleItem('Blog') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/gallery" v-if="NOTARIA.Theme.Menu.ShowGallery">{{
    getTitleItem('Gallery') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text :href="NOTARIA.NotariaVirtual"
          v-if="NOTARIA.Theme.Menu.ShowNV && NOTARIA.NotariaVirtual">{{ $t('NotariaVirtual') }}</v-btn>

        <v-btn class="theme--light" :ripple="false" text to="/contacto" v-if="NOTARIA.Theme.Menu.ShowContacto">{{
    getTitleItem('Contacto') }}</v-btn>

        <a v-if="NOTARIA.Theme.Menu.ShowButton" class="rn-btn button-header-notin"
          :to="NOTARIA.Theme.Menu.Button.Click">
          <span>{{ NOTARIA.Theme.Menu.Button.Texto }}</span>
        </a>
        <span v-if="NOTARIA.Personalizar.Multilenguaje">
          <v-btn icon :title="entry.title" v-for="entry in compLanguajes" :ripple="false" text :key="entry.title"
            @click="changeLocale(entry.language)" class="ma-1">
            <img :src="'/img/' + entry.flag + '.png'" width="16" />
          </v-btn>
        </span>

      </nav>
      <v-app-bar-nav-icon class="ma-0 pa-0 hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer class="hidden-md-and-up header-one-page-nav-drawer-notin menu-header-notin pt-12"
      :color="NOTARIA.Theme.Menu.Fondo && NOTARIA.Theme.Menu.Fondo.Color" v-model="drawer" location="bottom" temporary
      v-if="drawer && NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Menu" fixed width="60vw">
      <nav>
        <v-btn absolute class="mt-0 boton-cerrar-drawer" icon @click="drawer = !drawer" v-html="iconSvg(closeIcon)"/>
           

        <v-list>
          <v-list-item :ripple="false" key="Accesibilidad" @click="store.toggleModoAccesible()" link
            aria-label="Ir a inicio" v-if="NOTARIA.Personalizar.Accesibilidad"
            :title="$t(store.ModoAccesible ? 'DesactivarModoAccesibilidad' : 'ActivarModoAccesibilidad')">
          </v-list-item>

          <v-list-item :ripple="false" key="Inicio" to="/" link aria-label="Ir a inicio"
            v-if="NOTARIA.Theme.Menu.ShowInicio" :title="getTitleItem('Inicio')">
          </v-list-item>

          <v-list-item :ripple="false" key="Notarios" to="/notarios" link v-if="NOTARIA.Theme.Menu.ShowNotarios"
            :title="getTitleItem('Notario')">
          </v-list-item>

          <v-list-item :ripple="false" key="Equipo" to="/equipo" link v-if="NOTARIA.Theme.Menu.ShowEquipo"
            :title="getTitleItem('Equipo')">
          </v-list-item>

          <v-list-item :ripple="false" key="Servicios" to="/servicios" link v-if="NOTARIA.Theme.Menu.ShowServicios"
            :title="getTitleItem('Servicios')">
          </v-list-item>

          <v-list-item :ripple="false" key="Blog" to="/blog" link v-if="NOTARIA.Theme.Menu.ShowBlog"
            :title="getTitleItem('Blog')">
          </v-list-item>

          <v-list-item :ripple="false" key="Gallery" to="/gallery" link v-if="NOTARIA.Theme.Menu.ShowGallery"
            :title="getTitleItem('Gallery')">
          </v-list-item>

          <v-list-item :ripple="false" key="NV" :to="NOTARIA.NotariaVirtual" link
            v-if="NOTARIA.Theme.Menu.ShowNV && NOTARIA.NotariaVirtual" :title="$t('NotariaVirtual')">
          </v-list-item>

          <v-list-item :ripple="false" key="Contacto" to="/contacto" link v-if="NOTARIA.Theme.Menu.ShowContacto"
            :title="getTitleItem('Contacto')">
          </v-list-item>

        </v-list>
        <span v-if="NOTARIA.Personalizar.Multilenguaje">
          <v-btn icon :title="entry.title" v-for="entry in compLanguajes" :ripple="false" text :key="entry.title"
            @click="changeLocale(entry.language)" class="ma-1">
            <img :src="'/img/' + entry.flag + '.png'" width="16" />
          </v-btn>
        </span>
      </nav>

    </v-navigation-drawer>

    <!-- End top header navbar -->
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import feather from "feather-icons";
const route = useRoute()

import { useStore } from '../../store/index.ts'
const store = useStore()

import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()

const { NOTARIA } = storeToRefs(store)

const drawer = ref(false)
const icon = ref("menu")
const closeIcon = ref("x")
const scrolled = ref('')

const compLanguajes = computed(() => {
  return store.languages.filter(l => store.NOTARIA.LangSelected ? store.NOTARIA.LangSelected.includes(l.flag) : [])
})


function iconSvg(icon) {
  return feather.icons[icon].toSvg();
}
async function changeLocale(locale) {
  await store.setIdioma(locale)
  var routeSplited = route.fullPath.split('/')
  if(locale=='es'){
    if(routeSplited[1].length==2){
      routeSplited.splice(1,1)
    }
  }else{
    if(routeSplited[1].length==2){
      routeSplited[1] = locale
    }else{
      routeSplited.splice(1,0,locale)
    }
  }
  var newRoute = routeSplited.join('/')
  location.href = location.origin+newRoute
  //console.log(newRoute)
  // await store.initNotaria(null)
  // await navigateTo(newRoute)
  // console.log(newRoute)
  //i18n.locale = locale;
  //await store.setIdioma(locale)
}

function getTitleItem(value) {
  switch (value) {
    case "Gallery":
      return store.NOTARIA.Theme.Gallery.Titulo && store.NOTARIA.Theme.Gallery.Titulo.Texto ? store.NOTARIA.Theme.Gallery.Titulo.Texto : $t('Galeria')
    case "Blog":
      return store.NOTARIA.Theme.Blog.Titulo && store.NOTARIA.Theme.Blog.Titulo.Texto ? store.NOTARIA.Theme.Blog.Titulo.Texto : $t('Blog')
    case "Contacto":
      return store.NOTARIA.Theme.Contacto.Titulo && store.NOTARIA.Theme.Contacto.Titulo.Texto ? store.NOTARIA.Theme.Contacto.Titulo.Texto : $t('Contacto')
    case "Servicios":
      return store.NOTARIA.Theme.Servicios.Titulo && store.NOTARIA.Theme.Servicios.Titulo.Texto ? store.NOTARIA.Theme.Servicios.Titulo.Texto : $t('Servicios')
    case "Equipo":
      return store.NOTARIA.Theme.Team.Titulo && store.NOTARIA.Theme.Team.Titulo.Texto ? store.NOTARIA.Theme.Team.Titulo.Texto : $t('Equipo')
    case "Notario":
      if (store.NOTARIA.Notarios.length == 1)
        return store.NOTARIA.Theme.Notarios.Titulo && store.NOTARIA.Theme.Notarios.Titulo.Texto ? store.NOTARIA.Theme.Notarios.Titulo.Texto : $t('Notario')
      else if (store.NOTARIA.Notarios.length > 1)
        return store.NOTARIA.Theme.Notarios.Titulo && store.NOTARIA.Theme.Notarios.Titulo.Texto ? store.NOTARIA.Theme.Notarios.Titulo.Texto : $t('Notarios')
    case "Inicio":
      return store.NOTARIA.Theme.Home.Titulo && store.NOTARIA.Theme.Home.Titulo.Texto ? store.NOTARIA.Theme.Home.Titulo.Texto : $t('Inicio')
  }
}
function onScroll(e) {
  if (typeof window === "undefined") return;
  const top = window.scrollY || 0;
  var r =  '';
  if(top > 0){
    if(store.NOTARIA.Theme.Menu.Fixed){
      var r = " v-app-bar--is-scrolled"
    }
    if(!store.NOTARIA.Theme.DatosContacto.Fixed){
      r += ' top0'
    }
  }
  
  scrolled.value = r
}
</script>
<style lang="scss" scoped>
.boton-cerrar-drawer{
  position: fixed;
  right: 10px;
  top:40px;
  z-index: 99999;
}
.top0{
  top:0 !important;
}
.v-app-bar.v-toolbar{
  //background: unset !important;
}
</style>