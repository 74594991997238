<template>
  <div id='social-chat'>
    <VueSocialChat v-if="agentes && agentes.length>0" :attendants="agentes" class="tituloChat notin-socialchat">
      <span slot="header" v-if="NOTARIA.SocialChat.Titulo">{{ NOTARIA.SocialChat.Titulo }}</span>
      <!-- <template v-slot:button>
            <img
            src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
            alt="icon whatsapp"
            aria-hidden="true"
            >      
        </template> -->
      <template v-slot:button="{ open }" v-if="NOTARIA.SocialChat.Button">
        <span v-show="!open">{{ NOTARIA.SocialChat.Button }}</span>
        <span v-show="open">Cerrar</span>
      </template>
      <small slot="footer" v-if="NOTARIA.SocialChat.Footer" centered>{{ NOTARIA.SocialChat.Footer }}</small>
    </VueSocialChat>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '../../store'
const  { NOTARIA }  = useStore()
//const { NOTARIA } = storeToRefs(store)

import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()
import { useStyles } from '../../store/styles'
const style = useStyles();

const estiloTituloColor = style.ThemeHomeSocialChat?.css?.Color ? style.ThemeHomeSocialChat?.css?.Color : ""
// const estiloTituloBackgroundColor = style.ThemeHomeSocialChat?.css?.BackgroundColor ? style.ThemeHomeSocialChat?.css?.BackgroundColor : ""
const estiloTituloShadow = style.ThemeHomeSocialChat?.css?.BackgroundColor ? "4px 2px 6px "+style.ThemeHomeSocialChat.css.BackgroundColor:""
const estiloTituloFont = style.ThemeHomeSocialChat?.css?.Font ? style.ThemeHomeSocialChat?.css?.Font : ""
const estiloTituloSize = style.ThemeHomeSocialChat?.css?.Size ? style.ThemeHomeSocialChat?.css?.Size : ""
const estiloTituloWeight = style.ThemeHomeSocialChat?.css?.Weight ? style.ThemeHomeSocialChat?.css?.Weight : ""
const estiloTituloAlign = style.ThemeHomeSocialChat?.css?.Align ? style.ThemeHomeSocialChat.css.Align : ""
const agentes = ref([])

onMounted(()=>{
  if (NOTARIA.SocialChat.Agentes && agentes.value.length==0) {
    NOTARIA.SocialChat.Agentes.forEach(a => {
      var agente = {
        app: a.app?.toLowerCase(),
        label: a.label,
        name: a.name,
        id: a.id,
        number: a.number,
        username: a.username,
        href: a.href,
        avatar: {
          src: imagenIDtoSRC(a.Imagen)
        }
      }      
      agentes.value.push(agente)
    });
  } else {
    NOTARIA.SocialChat.Show = false
  }
})
</script>
<style>
.tituloChat{
    color:v-bind(estiloTituloColor)!important;
    text-shadow: v-bind(estiloTituloShadow) !important;
    font-family: v-bind(estiloTituloFont);
    font-size: v-bind(estiloTituloSize) !important;
    font-weight: v-bind(estiloTituloWeight) !important;
    text-align: v-bind(estiloTituloAlign)!important;
  }
  .tituloChat a {
    color:v-bind(estiloTituloColor)!important;
    text-shadow: v-bind(estiloTituloShadow) !important;
    font-family: v-bind(estiloTituloFont);
    font-size: v-bind(estiloTituloSize) !important;
    font-weight: v-bind(estiloTituloWeight) !important;
    text-align: v-bind(estiloTituloAlign)!important;
    
    a:visited {
      color:v-bind(estiloTituloColor)!important;
      .vsc-popup-body__link-info__label
      {
        font-size: v-bind(estiloTituloSize) !important;
        font-weight: v-bind(estiloTituloWeight) !important;
        text-align: v-bind(estiloTituloAlign)!important;
      }
    }

    .vsc-popup-body__link-info__label
    {
      font-size: v-bind(estiloTituloSize) !important;
      font-weight: v-bind(estiloTituloWeight) !important;
      text-align: v-bind(estiloTituloAlign)!important;
    }
  }
  

</style>