<template>
  <div v-if="NOTARIA">

      <!-- Start Header Area -->
      <StickyBar v-if="NOTARIA.Theme && NOTARIA.Theme.DatosContacto && NOTARIA.Theme.DatosContacto.MostrarStickyBar"/>
      <HeaderNotinOne/>

      <v-main :class="compMainClass" id="main">
        <slot>
          <NuxtPage/>

        </slot>
      </v-main>
      <!-- Start Footer Area  -->
      <FooterNotinOne color="1" />
      <!-- End Footer Area  -->
    </div>
    
    <ClientOnly>
      <ElementsButtonUp />    
      <Cookies/>
      <ContactSocialChat v-if="store.NOTARIA.SocialChat.Show"/>
    </ClientOnly>
</template>
<script setup>
import {useStore} from '../store/index.ts'
const store = useStore()
import {useStyles} from '../store/styles.ts'
const styles = useStyles()
const {NOTARIA} = storeToRefs(store)
const compMainClass=computed(()=>{
  var r=''
  if(store.NOTARIA.Theme.Menu.Transparent){
    
  }
  if( store.NOTARIA.Theme.DatosContacto.MostrarStickyBar ){
      r=' pt-7'

    }else{
      r=' pt-0'
    }
  if(store.NOTARIA.Theme.DatosContacto.Fixed){
  }
  return r
})


</script>
<style>
  div.contentlegal div p, div.contentlegal p,.contentlegal a, .contentlegal p, .contentlegal label
  {
    text-shadow:v-bind(styles.ThemeGeneralTextoCss.BackgroundColor);
    color:v-bind(styles.ThemeGeneralTextoCss.Color);
    font-family:v-bind(styles.ThemeGeneralTextoCss.Font);
    font-size:v-bind(styles.ThemeGeneralTextoCss.Size);
    text-align: justify;
  }
</style>