<template>
  <div id="stickyBar">
    <v-app-bar class="v-app-bar-fixed-mobile " :color="NOTARIA.Theme.DatosContacto.StickyBarCSS.BackgroundColor" height="30"
      :absolute="!NOTARIA.Theme.DatosContacto.Fixed">
      <v-col cols="12" class="align-self-start ma-0 pt-0" style="text-align: start;">
      <p class="stickybar-text">
        <a :href="'tel:' + NOTARIA.Contacto.Telefono"
          :title="'Marcar telefono de la notaría: ' + NOTARIA.Contacto.Telefono"
          :color="NOTARIA.Theme.DatosContacto.Letra.BackgroundColor" class="mt-0" :style="{
            color: NOTARIA.Theme.DatosContacto.Letra.BackgroundColor,
          }">
          <v-icon class="mr-1 mt-0" small :color="NOTARIA.Theme.DatosContacto.Letra.BackgroundColor"> mdi-phone
          </v-icon>
          {{ NOTARIA.Contacto.Telefono }}
        </a>
        <b :style="{
            color: NOTARIA.Theme.DatosContacto.Letra.BackgroundColor
          }"> | </b>
        <a :href="NOTARIA.Contacto.EnlaceMaps" title="Abrir dirección de la notaría en google maps" class="mt-0" :style="{
            color: NOTARIA.Theme.DatosContacto.Letra.BackgroundColor
          }">
          <v-icon class="mr-1" small :color="NOTARIA.Theme.DatosContacto.Letra.BackgroundColor"> mdi-map-marker
          </v-icon>
          
          {{ NOTARIA.Contacto.Direccion }}
          {{ NOTARIA.Contacto.CP }},
          {{ NOTARIA.Contacto.Municipio }}, {{ NOTARIA.Contacto.Provincia }}
          <!-- {{ NOTARIA.Theme.DatosContacto.Fixed?'fixed':'nofixed' }} -->
        </a>
        <!-- </p>
      </v-col>
      <v-col cols="12" md="6" class="align-self-start  hidden-xs-only hidden-sm-only ma-0 pt-0" style="text-align: end;">
        <p class="stickybar-text"> -->
        <a v-if="NOTARIA.Contacto.Email" :href="'mailto:' + NOTARIA.Contacto.Email"
          :title="'Enviar correo electronico a la dirección de la notaría: ' + NOTARIA.Contacto.Email" :style="{
      color: NOTARIA.Theme.DatosContacto.Letra.BackgroundColor
    }">
          <v-icon class="mx-1" small :color="NOTARIA.Theme.DatosContacto.Letra.BackgroundColor"> mdi-email </v-icon>
          {{ NOTARIA.Contacto.Email }}
        </a>
        </p>
      </v-col>
    </v-app-bar>
  </div>
</template>
<script setup lang="ts">
import { useStore } from '../store'
const store = useStore();
const { NOTARIA } = storeToRefs(store)

</script>
<style lang="css" scoped>
.v-app-bar-fixed-mobile .stickybar-text{
  height: 30px !important;
  z-index: 99999;
  font-size: calc(0.5em + 0.4vw) !important;
  line-height: calc(0.5em + 1.6vw) !important;
  vertical-align: top;
}
.stickybar-text{
  /* text-overflow: ellipsis;
  overflow: hidden; */
}
</style>