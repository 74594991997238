<template>
    <div id="subfooter">
        <div id="wrapperSubFooter" v-html="NOTARIA.Theme.Footer.SubFooter"/>
    </div>
</template>
<script setup lang="ts">
import { useStore } from '../../store/index.ts'
const { NOTARIA } = useStore()
</script>
<style lang="css" scoped>

    #wrapperSubFooter{
        padding:25px;
        margin:20px;
    }
</style>